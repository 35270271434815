import { post } from '@rails/request.js';

document.addEventListener("turbo:load", () => {
  if (!document.querySelector("body.payment_method.new")) {
    return;
  }

  const stripe = Stripe(document.querySelector("meta[name='stripe_publishable_key']").content);
  const elements = stripe.elements();
  const card = elements.create("card");
  card.mount("#card");

  const form = document.getElementById("account_payment_method_form");
  const message = document.getElementById("payment_method_message");
  const accountId = document.getElementById("account_id").value; 

  form.addEventListener("submit", async (event) => {
    event.preventDefault();

    const {paymentMethod, error} = await stripe.createPaymentMethod({
      type: "card",
      card,
    });

    if (error) {
      message.textContent = error.message;
    } else {
      const response = await post(`/accounts/${accountId}/payment_method`, {
        body: JSON.stringify({
          payment_method_id: paymentMethod.id,
        }),
      });

      if (response.ok) {
        message.textContent = "Payment method updated successfully";
        window.location.href = `/accounts/${accountId}`;
      } else {
        message.textContent = "Failed to update payment method";
      }
    }
  });
});
